<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="8" lg="6" xl="6">
        <Loader v-if="loading" />
        <div v-else>
          <h1>Termes and Conditions</h1>
          <div class="ml-4 mr-4" v-html="termsAndConditions">
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Loader from "@/components/Admin/Loader";

import PublicOrganizationService from "@/services/publicorganizationservice";

export default {
  components: {
    Loader,
  },
  data() {
    return {
      loading: true,
      termsAndConditions: null,
    };
  },
  methods: {

  },
  computed: {
    organizationUrl() {
      return this.$route.params.organizationUrl;
    },
  },
  async mounted() {
    try {
      this.loading = true;

      const termsAndConditions = await PublicOrganizationService.getTermsAndConditions(
        this.organizationUrl
      );

      this.termsAndConditions = termsAndConditions.data;

      this.loading = false;

    } catch (error) {
      console.dir(error);
    }
  },
};
</script>